.button {
  background-color: var(--color-felblauw);
  border-radius: var(--border-radius);
  border: none;
  color: var(--color-wit);
  cursor: pointer;
  display: inline-block;
  font-family: var(--font-family-body);
  font-size: var(--font-size-m);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-m);
  padding: 1.2rem 1.6rem;
  text-decoration: none;
  transition:
    background-color 200ms ease-out,
    color 200ms ease-out;
  white-space: nowrap;

  &:hover,
  &:focus {
    background-color: var(--color-donkerblauw);
  }

  &[disabled] {
    opacity: 0.2;
  }
}

.small {
  color: var(--color-wit);
  font-size: var(--font-size-m);
  padding: 0.4rem 0.8rem;

  &:hover,
  &:focus {
    background-color: var(--color-donkerblauw);
    cursor: pointer;
  }
}

.buttonInner {
  align-items: center;
  display: flex;
  background-color: none;

  &:hover,
  &:focus {
    background-color: none;
  }

  & svg {
    height: 1.6rem;
    width: 1.6rem;
  }

  & > :not(:last-child) {
    margin-right: 0.8rem;
  }
}

.lichtBlauw {
  background-color: var(--color-lichtblauw);
  color: var(--color-zwart);

  &:hover,
  &:focus {
    background-color: var(--color-felblauw);
    color: var(--color-wit);
  }
}

.groen {
  background-color: var(--color-groen);
  color: var(--color-wit);

  &:hover,
  &:focus {
    background-color: var(--color-donkerblauw);
  }
}

.rood {
  background-color: var(--color-transparent);
  color: var(--color-rood);
  border: 0.1rem solid var(--color-rood);

  &:hover,
  &:focus {
    opacity: 0.8;
    background-color: transparent;
  }
}

.bright {
  background-color: var(--color-felblauw);
  color: var(--color-felblauw);

  &:hover,
  &:focus {
    background-color: var(--color-donkerblauw);
  }
}

.donkerblauw {
  background-color: var(--color-donkerblauw);

  &:hover,
  &:focus {
    background-color: var(--color-felblauw);
  }
}

.transparent {
  background-color: transparent;
  color: var(--color-zwart);

  &:hover,
  &:focus {
    background-color: var(--color-wit);
  }
}

.outLine {
  background-color: transparent;
  border: 0.1rem solid currentcolor;
}
