.item {
  color: var(--color-zwart);
  display: flex;
  font-size: var(--font-size-m);
  justify-content: space-between;
  padding: 1.6rem;

  &:not(:last-child) {
    border-bottom: solid var(--color-bordergrijs) 0.1rem;
  }
}

.children {
  text-align: right;
}
