.container {
  margin: 0 auto;
  max-width: var(--container-l);
  padding: 0 2.4rem;
  width: 100%;
}

.small {
  max-width: var(--container-s);
}

.medium {
  max-width: var(--container-m);
}
