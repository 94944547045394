.container {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  position: relative;
}

.indicator {
  width: 100%;
  height: 1.6rem;
  background-color: var(--color-bordergrijs);
  border-radius: 1.6rem;
}

.value {
  color: var(--color-grijsblauw);
  position: relative;
}

.percentageIndicator {
  background-color: green;
  height: 100%;
  border-radius: 1.6rem;
}

.small {
  height: 0.8rem;
  border-radius: 0.8rem;
}

.green {
  background-color: var(--color-groen);
}

.orange {
  background-color: var(--color-oranje);
}

.red {
  background-color: var(--color-rood);
}
