.forgotPassword {
  background: none;
  border: none;
  color: var(--color-lichtgrijs);
  cursor: pointer;
  transition: color 0.2s;

  &:hover,
  &:focus {
    color: var(--color-grijs);
  }
}
