.container {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0 0 3.2rem;
}

.title {
  color: var(--color-zwart);
  font-size: var(--font-size-xl);
  line-height: var(--line-height-s);
  margin: 0;
}

.buttonContainer {
  align-items: center;
  display: flex;

  & > :not(:last-child) {
    margin-right: 1.6rem;
  }
}
