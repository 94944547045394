.container h1,
.container h2,
.container h3,
.container h4 {
  color: var(--color-zwart);
  margin: 0;
}

.container h1:not(:last-child),
.container h2:not(:last-child),
.container h3:not(:last-child),
.container h4 :not(:last-child) {
  margin: 0 0 0.5em;
}

.container p {
  font-size: var(--font-size-m);
  line-height: var(--line-height-m);
  margin: 0;
}

.container p:not(:last-child) {
  margin: 0 0 1.2em;
}

.container ul,
.container ol {
  font-size: var(--font-size-m);
  line-height: var(--line-height-m);
  margin: 0;
}

.container ol:not(:last-child),
.container ul:not(:last-child) {
  margin: 0 0 1.2em;
}

.center {
  text-align: center;
}
