.container {
  display: flex;
  gap: 1.2rem;

  & input {
    background-color: var(--color-wit);
    border-radius: var(--border-radius);
    border: 0.1rem solid var(--color-bordergrijs);
    color: var(--color-zwart);
    display: block;
    font-size: var(--font-size-m);
    line-height: var(--line-height-s);
    padding: 0.8rem 1rem;
    resize: none;
    width: 100%;

    &:is(:focus, :active) {
      border-color: var(--color-donkerblauw);
      outline: none;
    }

    &:disabled {
      background-color: var(--color-grijs);
      cursor: not-allowed;
    }
  }
}
