.container {
  background-color: var(--color-lichtblauw);
  display: flex;
  flex-direction: column;
  min-width: 20rem;
  overflow: hidden;
  position: relative;
  height: 100vh;
}

.containerBackground {
  content: '';
  background-color: var(--color-felblauw);
  border-radius: 50%;
  bottom: 0;
  height: 75vh;
  left: 0;
  opacity: 0.1;
  position: absolute;
  transform: translate(-12.5%, 25%);
  width: 75vh;
}

.logo {
  color: var(--color-zwart);
  display: block;
  font-size: var(--font-size-l);
  line-height: 1;
  padding: 2.4rem;
  text-decoration: none;
  width: 100%;

  & span {
    color: var(--color-donkerblauw);
  }
}

.navigation {
  margin: 2.4rem 0;
  flex: 1;

  & ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
}

.navigationLink {
  align-items: center;
  background-color: transparent;
  border: 0;
  border-left: solid 0.2rem transparent;
  color: var(--color-grijsblauw);
  cursor: pointer;
  display: flex;
  font-size: var(--font-size-m);
  line-height: var(--line-height-s);
  padding: 1.6rem 2.4rem;
  position: relative;
  text-decoration: none;
  transition:
    color 200ms ease-out,
    border-color 200ms ease-out;
  width: 100%;

  &:hover,
  &:focus,
  &.active {
    border-color: var(--color-donkerblauw);
    color: var(--color-donkerblauw);
  }

  & svg {
    height: 2.8rem;
    margin-right: 0.8rem;
    width: 2.8rem;
  }
}
