.container {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
}

.main {
  flex: 1;
  height: 100vh;
  overflow-y: scroll;
  padding: 4.8rem;
}
