.container {
  background-color: var(--color-wit);
  border-radius: var(--border-radius);
  box-shadow: 0 0.2rem 0.8rem rgb(0 0 0 / 4%);
  padding: 2.4rem;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
}

.upperContainer {
  display: flex;
  justify-content: space-between;
}

.ratio {
  margin-left: 3.2rem;
}

.dataListCard div {
  padding-left: 0;
}

.iconContainer {
  height: 4rem;
  opacity: 0.2;
  position: relative;
  width: 4rem;
}

.currentAmount {
  color: var(--color-zwart);
  font-weight: var(--font-weight-bold);
}

.total {
  color: var(--color-grijsblauw);
}
