.labelContainer {
  align-items: center;
  color: var(--color-zwart);
  display: flex;
  font-size: var(--font-size-m);
  justify-content: space-between;
  line-height: var(--line-height-s);
  margin: 0 0 0.5em;
}

.optional {
  font-size: var(--font-size-s);
  margin-left: 0.8rem;
}

.description {
  font-size: var(--font-size-s);
  line-height: var(--line-height-s);
  margin: 0 0 0.5em;
}

.error {
  color: var(--color-rood);
  font-size: var(--font-size-s);
  line-height: var(--line-height-s);
  margin: 0.5em 0 0;
}
