.form > :not(:last-child) {
  margin-bottom: 1.6rem;
}

.buttonContainer {
  align-items: center;
  display: flex;

  & > :not(:last-child) {
    margin-right: 1.6rem;
  }
}

.error {
  color: var(--color-rood);
  font-size: var(--font-size-m);
  line-height: var(--line-height-s);
  margin: 1em 0 0;
}
