.container {
  background-color: var(--color-wit);
  border-radius: var(--border-radius);
  padding: 2rem 0 0.4rem;
}

.header {
  display: flex;
  justify-content: space-between;
  padding: 0 1.6rem 0.8rem;
}
